import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

interface ArtSpecRow {
    groupId: number;
    groupName: string;
    label: string;
    value: string;
}

interface ArtSpecData {
    data: ArtSpecRow[];
}

export default class TKArtSpecTable extends TKCustomElementFactory {
    links: NodeListOf<HTMLAnchorElement>;
    sections: NodeListOf<HTMLElement>;
    constructor() {
        super();

        this.links = this.querySelectorAll('[data-tk-jump-nav-items] a');

        const sectionIds = new Set<string>();
        this.links.forEach((link) => {
            const href = link.getAttribute('href');
            if (!href) return;
            sectionIds.add(href);
        });

        this.sections = this.querySelectorAll(
            Array.from(sectionIds).join(', '),
        );
    }

    connectedCallback(): void {
        this.links.length && this.registerClickListener();
    }

    registerClickListener() {
        const handleClick = this.handleClick.bind(this);

        this.links.forEach((link) => {
            this.pushListener({
                event: 'click',
                element: link,
                action: handleClick,
            });
        });
    }

    handleClick(e: Event): void {
        e.preventDefault();

        // Scroll section into view
        const link = e.target as HTMLAnchorElement;
        const href = link.getAttribute('href');

        if (!href) return;

        const section = this.querySelector(href);
        if (!section) return;

        // TODO make this really smooth
        section.scrollIntoView({ block: 'start', behavior: 'smooth' });

        // Change active link
        this.links.forEach((link) => {
            link.classList.remove('tk-jump-nav__link--active');
        });

        link.classList.add('tk-jump-nav__link--active');
    }

    // TODO Add IntersectionObserver to set active link on scroll
    addIntersectionObserver() {}
}
