import { RectangleItem, isOutsideShapes } from '@tk/utilities/tk.shape.detector';

export default class TKButtonDropdown extends HTMLElement {
    dialog?: HTMLDialogElement;
    button?: HTMLButtonElement;
    selectedClassName: string;
    openingType: string;
    alignedRightClassName: string;
    onShowEvent: () => void;
    onCloseEvent: (event: MouseEvent) => void;

    constructor() {
        super();

        this.dialog = this.querySelector('dialog') || undefined;
        this.button = this.querySelector('button') || undefined;
        this.selectedClassName = this.getAttribute('data-selected-class-name') || 'tk-button__account--selected';
        this.openingType = this.getAttribute('data-opening-type') || 'hover';
        this.alignedRightClassName = this.getAttribute('data-aligned-right-class-name') || 'tk-button__account--right';
        this.onShowEvent = () => {};
        this.onCloseEvent = () => {};
    }

    disconnectedCallback() {
        this.removeEventListener('mouseenter', this.onShowEvent);
        this.dialog?.removeEventListener('mouseleave', this.onCloseEvent);
    }

    connectedCallback() {
        this.registerOpenListener();
    }

    registerOpenListener() {
        this.onShowEvent = this.show.bind(this);
        if (this.openingType === 'click') {
            this.addEventListener('click', this.onShowEvent);
        } else {
            this.addEventListener('mouseenter', this.onShowEvent);
        }
    }

    registerCloseListener() {
        this.onCloseEvent = this.close.bind(this);

        console.log(this.openingType);
        if (this.openingType === 'click') {
            window.addEventListener('click', this.onCloseEvent);
        } else {
            this.addEventListener('mouseleave', this.onCloseEvent);
            this.dialog?.addEventListener('mouseleave', this.onCloseEvent);
        }
    }

    show() {
        if (!this.dialog || !this.button) return;
        this.dialog.show();
        this.button.classList.add(this.selectedClassName);
        const { left } = this.button.getBoundingClientRect();
        const leftPosition = left + window.scrollX;
        if (window.outerWidth <= this.dialog.clientWidth + leftPosition) {
            this.dialog.classList.add(this.alignedRightClassName);
        }

        setTimeout(() => {
            this.registerCloseListener();
        }, 0);
    }

    close(event: MouseEvent) {
        if (!this.dialog || !this.button) return;
        const dialogDimensions = this.dialog.getBoundingClientRect();
        const buttonDimensions = this.button.getBoundingClientRect();
        const rectangles: RectangleItem[] = [
            { rectangle: dialogDimensions },
            {
                rectangle: buttonDimensions,
                offset: { bottom: dialogDimensions.top - buttonDimensions.bottom },
            },
        ];
        if (isOutsideShapes(event, rectangles)) {
            this.button.classList.remove(this.selectedClassName);
            this.dialog.close();
        }
    }
}
