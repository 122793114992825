import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { RectangleItem, isOutsideShapes } from '@tk/utilities/tk.shape.detector';

export default class KDMZSelectBox extends TKCustomElementFactory {
    buttons: NodeListOf<HTMLButtonElement>;
    action: string;
    selectbox?: HTMLElement;
    selectboxhead?: HTMLElement;
    selectboxbody?: HTMLElement;

    selectboxHeadSelector: string;
    selectlistSelector: string;
    selectedClass?: string;
    openClass?: string;
    doneTypingInterval: number;
    currentSelector?: HTMLInputElement;

    constructor() {
        super();

        this.buttons = this.querySelectorAll('button');
        this.action = this.getAttribute('data-tk-action') || window.location.href;
        this.selectbox = this.closest(".kdmz-selectbox") || undefined;
        this.selectboxhead = this.querySelector('[data-tk-select-box-head]') || undefined;
        this.selectboxbody = this.querySelector('[data-tk-select-box-body]') || undefined;

        this.selectboxHeadSelector = "[data-tk-select-box-head]";
        this.selectlistSelector = ".select-list";
        this.selectedClass = 'is-selected';
        this.openClass = 'is-open';
        this.doneTypingInterval = 750;
        this.currentSelector = undefined;
    }

    connectedCallback(): void {
        if (!this.selectbox || !this.selectboxhead || !this.selectboxbody) throw new Error('KDMZSelectBox: Selectbox is missing!');
        this.registerClickListener();
    }

    registerClickListener() {
        const open = this.open.bind(this);
        const pickCheckboxValue = this.pickCheckboxValue.bind(this);
        this.setValuesToField(undefined, true);

        this.pushListener({
            event: 'click',
            element: this.selectboxhead!,
            action: open,
        });

        this.pushListener({
            event: 'click',
            element: this.selectboxbody!,
            action: pickCheckboxValue,
        });

        this.doneTyping(this.doneTypingInterval, this.querySelector('.selectbox-body-search input[type="text"]') as HTMLInputElement);
    }

    open(event: Event){
        if (!event.target) return;

        this.classList.toggle("is-open");
        setTimeout(() => {
            this.registerCloseListener();
        }, 0);
    }

    registerCloseListener() {
        const handleClose = this.close.bind(this);
        this.pushListener({ event: 'click', element: window, action: handleClose });
    }

    close(event: MouseEvent) {
        if (!this.selectboxhead || !this.selectboxbody) return;
        const buttonRect = this.selectboxhead.getBoundingClientRect();
        const dropdownRect = this.selectboxbody.getBoundingClientRect();
        const rectangles = [
            { rectangle: buttonRect },
            { rectangle: dropdownRect },
        ] as RectangleItem[];
        if (isOutsideShapes(event, rectangles)) {
            this.classList.remove("is-open");
            this.removeListener(window);
        }
    }

    pickCheckboxValue(event: Event) {
        if (!event.target) return;
        let _target = event.target as HTMLElement;

        if (_target !== null){
            this.setValuesToField(_target.closest(this.selectlistSelector + ' [type="checkbox"]') as HTMLInputElement);
        }
    }

    /* uebernommen aus kdmz2 */
    setValuesToField(elem?:HTMLInputElement, onstart?:boolean): void {
        let delimiter = this.dataset.opDelimiter ? this.dataset.opDelimiter : ";";
        let selectBoxType = this.dataset.selectboxType ? this.dataset.selectboxType : "multi";
        let textfield = this.querySelector<HTMLInputElement>(this.selectboxHeadSelector + ' input[data-type="values"]');
        if (!textfield) return;
        textfield.value = "";
        let showSelected = this.querySelector<HTMLInputElement>(this.selectboxHeadSelector + " input.show-selected");
        if (!showSelected) return;
        showSelected.value = "";
        let checkboxes = this.querySelectorAll<HTMLInputElement>('[type="checkbox"]:checked');
        let checkboxesChecked = this.querySelectorAll<HTMLInputElement>('[type="checkbox"]:checked');
        let values: string[] = [], valueNames: string[] = [];

        if (selectBoxType === "single" && elem != null){
            // einfache Selectbox
            let origElem = elem.cloneNode(true) as HTMLInputElement;
            checkboxes.forEach(checkbox => {
                checkbox.checked = false;
            });
            if (origElem.checked === true && elem != null){
                elem.checked = true;
                values.push(elem.value);
                const labelText = elem.closest<HTMLLIElement>("li")?.querySelector<HTMLLabelElement>("label")?.innerText;
                if (!labelText) return;
                valueNames.push(labelText);
            }
            this.classList.remove("is-open");
        } else {
            // einfache Multi-Selectbox
            checkboxesChecked.forEach(checkbox => {
            values.push(checkbox.value);
            const labelText = checkbox.closest<HTMLLIElement>("li")?.querySelector<HTMLLabelElement>("label")?.innerText;
            if (!labelText) return;
            valueNames.push(labelText);
            });
        }
        if (values.length === 1){
            showSelected.value = valueNames.join();
        } else if (values.length > 1){
            showSelected.value = valueNames.length + " selected";
        }
        textfield.value = values.join(delimiter);
        // if (!onstart && this.closest(".form-switcher")){
        //     // console.log("fire change");
        //     const event = new Event("change");
        //     // Dispatch the event.
        //     this.closest<HTMLElement>(".form-switcher").dispatchEvent(event);
        // }
    }

    searchBoxSearch(selector: HTMLInputElement): void {
        const input = selector;
        const filter = input.value.toUpperCase();
        const list = selector.closest('[data-tk-select-box-body]')?.querySelector(".select-list") as HTMLElement;
        const labels = list?.getElementsByTagName("label");

        if (labels) {
            for (let i = 0; i < labels.length; i++) {
                const txtValue = labels[i].textContent || labels[i].innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    labels[i].closest("li")?.classList.remove("hidden");
                } else {
                    labels[i].closest("li")?.classList.add("hidden");
                }
            }
        }

        if (list.querySelectorAll('li.hidden').length === list.querySelectorAll('li').length) {
            let xy = selector.closest(".selectbox-body-search")?.querySelector(".no-result") as HTMLElement;
            xy.style.display = "";
        } else {
            let xy = selector.closest(".selectbox-body-search")?.querySelector(".no-result") as HTMLElement;
            xy.style.display = "none";
        }
    }

    doneTyping(interval:number , selector:HTMLInputElement) {
        let typingTimer: ReturnType<typeof setTimeout> = 0;
        let doneTypingInterval = interval;
        const searchBoxKeyup = this.searchBoxKeyup.bind(this, selector, typingTimer, doneTypingInterval);
        const searchBoxReset = this.searchBoxReset.bind(this, selector);

        this.pushListener({
            event: 'keyup',
            element: selector,
            action: searchBoxKeyup,
        });

        const selectorParent = selector.parentElement;

        if (selectorParent){
            const resetBtn = selectorParent.querySelector<HTMLButtonElement>(".reset-button");

            if (resetBtn){
                this.pushListener({
                    event: 'click',
                    element: resetBtn,
                    action: searchBoxReset,
                });
            }
        }

    }

    searchBoxKeyup(selector:HTMLInputElement, typingTimer: ReturnType<typeof setTimeout>, doneTypingInterval: number, event: Event){
        clearTimeout(typingTimer);
        if (event.keyCode != '38' && event.keyCode != '40') {
            this.currentSelector = selector;
            typingTimer = setTimeout(this.searchBoxSearch.bind(this, selector), doneTypingInterval);
        }
    }

    searchBoxReset(selector:HTMLInputElement, event: Event){
        event.preventDefault();
        selector.value = "";
        this.searchBoxSearch(selector);
    }
}
