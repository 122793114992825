import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TKHeaderSearch extends TKCustomElementFactory {
    toggleButtons?: NodeListOf<HTMLButtonElement>;
    searchWrapper?: HTMLElement;

    activeClass = 'tk-search-overlay--active';

    constructor() {
        super();

        this.toggleButtons = this.querySelectorAll('[data-tk-search-toggle]') || undefined;
        this.searchWrapper = this.querySelector('[data-tk-search-wrapper]') || undefined;
    }

    connectedCallback(): void {
        this.registerClickListener();
    }

    registerClickListener() {
        const toggleSearch = this.toggleSearch.bind(this);

        this.toggleButtons?.forEach((button) => {
            this.pushListener({
                event: 'click',
                element: button,
                action: toggleSearch,
            });
        });
    }

    toggleSearch(): void {
        if (!this.searchWrapper) return;
        this.searchWrapper!.classList.toggle(this.activeClass, !this.searchWrapper!.classList.contains(this.activeClass));
        if (this.searchWrapper!.classList.contains(this.activeClass)){
            const fieldElement = this.searchWrapper.querySelector<HTMLInputElement>('#form-search');
            setTimeout(() => {
                fieldElement?.focus();
            }, 500);
        }
    }
}
