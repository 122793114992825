import TKModuleFactory from '@tk/utilities/tk.module.factory';
import TKManager from '@tk/basiscs/tk.manager';
import TKNavigationSidebar from '@tk/components/tk.navigation.sidebar';
import TKHeader from '@tk/components/tk.header';
import TKViewport from '@tk/basiscs/tk.viewport';
import TKBasketButton from '@tk/components/tk.basket.button';
import TKCarousel from '@tk/components/tk.carousel';
import TKContextMenu from '@tk/components/tk.context.menu';
import TKFlag from '@tk/components/tk.flag';
import TKProductTile from '@tk/components/tk.product.tile';
import TKProductVariants from '@tk/components/tk.product.variants';
import TKTooltip from '@tk/components/tk.tooltip';
import TKPrice from '@tk/components/tk.price';
import TKStock from '@tk/components/tk.stock';
import TKStaticContent from '@tk/controls/tk.static.content';
import TKTabsTab from '@tk/controls/tk.tabs.tab';
import TKArticleList from '@tk/components/tk.article.list';
import TKFavoriteButton from '@tk/components/tk.favorite.button';
import TKFavoriteInfo from '@tk/components/tk.favorite.info';
import TKFormValidator from '@tk/controls/tk.form.validator';
import TKTabsAccordion from '@tk/controls/tk.tabs.accordion';
import TKCompareListLink from '@tk/components/tk.compare.list.link';
import TKCompareButton from '@tk/components/tk.compare.button';
import TKSelection from '@tk/controls/tk.selection';
import TKTable from '@tk/components/tk.table';
import TKButtonDropdown from '@/controls/kdmz.button.dropdown';
import KDMZSearch from '@/components/kdmz.search';
import TKJumpNav from '@/components/tk.jump.nav';
import TKHeaderSearch from '@/components/tk.header.search';

// Custom KDMZ elements
import KDMZFormElement from '@/components/kdmz.form.element';
import KDMZSelectBox from '@/components/kdmz.select.box';
import KDMZDialog from '@/components/kdmz.dialog';
import KDMZMinibasket from '@/components/kdmz.minibasket';

import 'tippy.js/dist/tippy.css';
import '@splidejs/splide/dist/css/splide.min.css';
import '@iconfont/iconfont/material-icons.css';
import '@scss/modules/general.scss';
import '@scss/modules/cv-ces.scss';

export default class General extends TKModuleFactory {
    constructor() {
        super([
            { name: 'tk-manager', constructor: TKManager },
            { name: 'tk-viewport', constructor: TKViewport },
            { name: 'tk-button-dropdown', constructor: TKButtonDropdown },
            { name: 'tk-header', constructor: TKHeader },
            { name: 'tk-nav-sidebar', constructor: TKNavigationSidebar },
            { name: 'tk-basket-button', constructor: TKBasketButton },
            { name: 'tk-carousel', constructor: TKCarousel },
            { name: 'tk-context-menu', constructor: TKContextMenu },
            { name: 'tk-flag', constructor: TKFlag },
            { name: 'tk-product-tile', constructor: TKProductTile },
            { name: 'tk-product-variants', constructor: TKProductVariants },
            { name: 'tk-tooltip', constructor: TKTooltip },
            { name: 'tk-article-list', constructor: TKArticleList },
            { name: 'tk-price', constructor: TKPrice },
            { name: 'tk-stock', constructor: TKStock },
            { name: 'tk-static-content', constructor: TKStaticContent },
            { name: 'tk-tabs-tab', constructor: TKTabsTab },
            { name: 'tk-favorite-button', constructor: TKFavoriteButton },
            { name: 'tk-favorite-info', constructor: TKFavoriteInfo },
            { name: 'tk-form-element', constructor: KDMZFormElement },
            { name: 'tk-form-validator', constructor: TKFormValidator },
            { name: 'tk-tabs-accordion', constructor: TKTabsAccordion },
            { name: 'tk-compare-list-link', constructor: TKCompareListLink },
            { name: 'tk-compare-button', constructor: TKCompareButton },
            { name: 'tk-selection', constructor: TKSelection },
            { name: 'tk-jump-nav', constructor: TKJumpNav },
            { name: 'tk-header-search', constructor: TKHeaderSearch },
            { name: 'tk-table', constructor: TKTable },
            { name: 'kdmz-select-box', constructor: KDMZSelectBox },
            { name: 'tk-dialog', constructor: KDMZDialog },
            { name: 'tk-minibasket', constructor: KDMZMinibasket },
            { name: 'kdmz-search', constructor: KDMZSearch },
        ]);
    }
}

// eslint-disable-next-line no-new
new General();
