import TKFormElement from '@tk/controls/tk.form.element';

export default class KDMZFormElement extends TKFormElement {
    select?: HTMLSelectElement;

    constructor() {
        super();

        this.select = this.querySelector('select') as HTMLSelectElement;
    }

    connectedCallback() {
        super.connectedCallback();
        const onSelectChange = this.handleSelectChange.bind(this);

        if (this.select) {
            this.pushListener({ event: 'change', element: this.select, action: onSelectChange });
            this.handleSelectChange();
        }
    }

    /**
     * Handles changes to the select element.
     * Toggles the 'tk-form-select--filled' class based on the select element's value.
     */
    handleSelectChange(): void {
        if (!this.select) return;
        this.classList.toggle('tk-form-select--filled', this.select.value !== '');
    }
}
