import TKDialog from '@tk/components/tk.dialog';

export default class KDMZDialog extends TKDialog {
    shouldFullTop: boolean;
    fullTopClassName: string;

    constructor() {
        super();
        this.shouldFullTop = this.hasAttribute('data-tk-should-full-top');
        this.fullTopClassName = this.getAttribute('data-tk-full-top-class-name') || 'tk-dialog--full-top';
    }


    createDialog(url: string): HTMLDialogElement {
        const dialog = TKDialog.renderDialog({
            className: `${this.dialogClassName} ${this.shouldMaxWidth ? this.maxWidthClassName : ''} ${this.shouldFullTop ? this.fullTopClassName : ''}`,
            closeClassName: this.dialogCloseClassName,
            innerClassName: this.dialogInnerClassName,
            tag: 'dialog',
            isConfirm: this.isConfirm,
            confirmURL: url,
            confirmTitle: this.confirmTitle,
            confirmDescription: this.confirmDescription,
            confirmCancelIcon: this.confirmCancelIcon,
            confirmCancelLabel: this.confirmCancelLabel,
            confirmSubmitIcon: this.confirmSubmitIcon,
            confirmSubmitLabel: this.confirmSubmitLabel,
        });
        const onClosed = this.closedDialog.bind(this);
        this.pushListener({ event: 'close', element: dialog, action: onClosed });
        this.currentDialog = this.dialogs.push({ url, dialog }) - 1;
        return dialog;
    }
}
